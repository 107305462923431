import React from "react"
import Header from "../../components/header"
import {Helmet} from "react-helmet"

export default () => (
  <React.Fragment>
    <Helmet>
      <title>Commissioner Update - 2022 Season | Chode Dynasty</title>
    </Helmet>

    <Header />
    <main>
      <div className="row">
        <div className="inner">
          <h1>Commissioner Update - 2022 Season</h1>
          <nav>
            <ul>
              <li><a href="#draft">Draft</a></li>
              <li><a href="#changes">Changes</a></li>
              <li><a href="#rewards">Weekly Rewards</a></li>
            </ul>
          </nav>

          <h2 id="draft">Draft</h2>
          <p>The rookie draft date/time is Sunday, June 5th at 2:00PM. It will be held in person at the Commissioner's house, but you will be able to draft remotely using the Sleeper platform.</p>

          <h3>Details</h3>
          <p>The rookie draft will be 4 rounds. Draft order is listed below and will be linear, meaning that the order will be the same for all rounds. You're able to trade draft picks.</p>

          <p>Veterans/free agents will be available once the season starts, which should be immediately after the draft ends.</p>

          <h3>Order</h3>
          <p>
            <ol>
              <li>Jeff</li>
              <li>Frank</li>
              <li>Nick</li>
              <li>Ian</li>
              <li>Seth</li>
              <li>Joe</li>
              <li>Teddy</li>
              <li>Cory</li>
              <li>Clint</li>
              <li>Dan</li>
            </ol>
          </p>

          <h2 id="changes">Changes</h2>
          <p>There are no rule changes for this season.</p>
          
          
          <h2 id="rewards">Weekly Rewards</h2>
          <p>We're keeping the same weekly rewards as last year with an additonal weekly reward to match the extended season.</p>

          <p>
            <table className="styled rewards-cond">
              <thead>
                <th>Week</th>
                <th>Title</th>
                <th>Challenge</th>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                </tr>

                <tr>
                  <td>14</td>
                  <td>Lockdown</td>
                  <td>Team with the least amount of yards given up on defense</td>
                </tr>

                <tr>
                  <td>15</td>
                  <td>Consolation Prize</td>
                  <td>Team with the most total points that didn't make the playoffs</td>
                </tr>
              </tbody>
            </table>
          </p>
        </div>
      </div>
    </main>
  </React.Fragment>
)